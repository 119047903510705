import React from 'react';
import AvatarIcon1 from '../icons/download1.png';

export default function AvatarIcon() {
    const mystyle = {
     border:"1px solid #ccc",
     borderRadius:"60px",
     background:"#ffffff"
    };


  return (
    <img src={AvatarIcon1}  height="100" width="100" style={mystyle}/>
  );
}
