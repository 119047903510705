import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    height: '100%',
  },
 
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: Swoosh,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <div>
    
    
      <header class="header border-bottom header-sec">

         <div class="hidden-xs hidden-sm test3" >
      <div class="col-md-3 col-2 pr-0 h-75" >
        <a href="index.html">
        <TwilioLogo className={classes.twilioLogo} />
        </a>
      </div>
      
        


    </div>


</header>
    <div className={classes.background}>


  <div class="container-fluid">
  <div class="row test1">
  <div class="col-md-12">
  <div class="account-content">
  <div class="row align-items-center justify-content-center test" >
  <div class="col-md-6 col-lg-6 col-12">
      <center><img src="\olistica.png" class="img-fluid  w-25 mt-245" alt=""/></center>
  </div>
  <div class="col-md-6 col-lg-6 login-right mt-5 mb-2 col-12">
  <div class="row mb-4">
  <div class="col-md-5">
  <div class="login-header">
  <h3 class="color-cus1">Join <span class="font-weight-bold">MedAloha</span></h3>
  </div>
  </div>
  </div>
  <form action="#">
  <div class="row">
    
  </div>
  <div class="jumbotron text-center bg-white">
  <h4 class="display-3"><span class="text-green">Welcome</span>  Video/Audio Conversation</h4><p class="lead"><strong>Please Click on Join Now </strong>button for Discussion with your Member.</p><hr/><p>Having trouble? <a href="javascript:void(0)">Contact us</a></p>
  
    <div class="col-md-12">
   {user && location.pathname !== '/login' && <UserMenu />}
      {/*<div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <VideoLogo />
              <Typography variant="h6" className={classes.title}>
                Twilio Programmable Video
              </Typography>
            </div>
          </div>*/}
          <div className={classes.content}>{props.children}</div>
       {/* </div>*/}
        {props.subContent && <div className={classes.subContentContainer}>{props.subContent}</div>}
     {/* </div>*/}
    </div> 

   
 
  </div>
  
  </form>
 


  </div>


</div>



  </div>
  </div>
  </div>
  </div>
  
  </div>

      
     
    <footer class="footer">

  <div class="footer-top">
    <div class="container-fluid1 ">
      <div class="row1">
        <div class="col-lg-3 col-md-3">


          <div class="footer-widget footer-about">
            <div class="footer-logo">
              {/*<img src="assets/img/footer-logo.png" alt="logo">*/}
              <p class="text-white">Follow us. </p>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="#" target="_blank"><i class=" fab fa fa-facebook-f"></i> </a>
                  </li>
                  <li>
                    <a href="#" target="_blank"><i class="fab fa fa-twitter"></i> </a>
                  </li>
                  <li>
                    <a href="#" target="_blank"><i class="fab fa fa-linkedin-in"></i></a>
                  </li>
                  <li>
                    <a href="#" target="_blank"><i class="fab fa fa-instagram"></i></a>
                  </li>
                  <li>
                    <a href="#" target="_blank"><i class="fab fa fa-youtube"></i></a>
                  </li>

                </ul>

              </div>
            </div>
          </div>
          <div class="footer-about-content">
            <p class="text-white">Share us. </p>
            <div class="social-icon">
              <ul>
                <li>
                  <a href="#" target="_blank"><i class="fab fa fa-facebook-f"></i> </a>
                </li>
                <li>
                  <a href="#" target="_blank"><i class="fab fa-facebook-messenger"></i> </a>
                </li>
                <li>
                  <a href="#" target="_blank"><i class="fab fa fa-whatsapp"></i> </a>
                </li>

                <li>
                  <a href="#" target="_blank"><i class="fab fa fa-twitter"></i> </a>
                </li>
                        
                        <li>
                          <a href="#" target="_blank"><i class="fab fa fa-instagram"></i></a>
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 mt-4 hidden-xs ">
               {/*   <div id="carouselExampleSlidesOnly" class="carousel slide mb-3" data-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <p class="comment-content author-name">
                          "I was already planning private consultations by myself with health practitioners, by contacting and asking them for online sessions, but it took time and efforts…  MedAloha is exactly what I was waiting for."
                        </p>
                        <div class="meta-data mb-0 author-section">
                          <span class="comment-author font-italic author-name">Elisa</span>
                        </div>
                      </div>
                      <div class="carousel-item">

                        <p class="comment-content author-name">
                          "Super intuitive and user-friendly.!"
                        </p>
                        <div class="meta-data mb-0 author-section">
                          <span class="comment-author font-italic author-name">Andreas</span>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <p class="comment-content author-name">
                          "Discovering here new stuff about holistics and cool specialists from all over the world, will try more sessions soon."
                        </p>
                        <div class="meta-data mb-0 author-section">
                          <span class="comment-author font-italic author-name">Julie</span>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <p class="comment-content author-name">
                          "As a practitioner, MedAloha is the best way –especially in these covid times- to comfortably and easily connect from home with people from everywhere and to share my passion for holistic health."
                        </p>
                        <div class="meta-data mb-0 author-section">
                          <span class="comment-author font-italic author-name">Eros</span>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>

                <div class="col-lg-3 col-md-3 mobile_footer_top">
                  <div class="footer-widget footer-contact float-right">
                    <h2 class="footer-title">Contact Us</h2>
                    <div class="footer-contact-info">
                      <div class="footer-address">
                        <span><i class="fas fa fa-map-marker"></i></span>
                        <p class=""> 3556  Beech Street, San Francisco,<br/> California, CA 94108 </p>
                      </div>
                      <p>
                        <i class="fas fa fa-phone"></i>
                        +1 315 369 5943
                      </p>
                      <p class="mb-0">
                        <i class="fas fa fa-envelope-o"></i>
                        doccure@example.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row1">
                <div class="col-md-12">
                  <div class="  text-center">
                    <p class="text-white  footer-middle-text" >
                      Are you a Recognized Holistic Practitioner? <a href="http://medaloha.vipscollege.com/apply-specialist.html" class="text-success">Apply</a></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="footer-bottom">
              <div class="container-fluid1">
                <div class="copyright">
                  <div class="row1">
              <div class="col-md-6 col-lg-6">
                <div class="copyright-text">
                  <p class="mb-0 text-center-mobile">© 2021 MedAloha. All rights reserved.</p>
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <div class="copyright-menu">
                  <ul class="policy-menu">
                    <li><a href=" terms-and-conditions.html">Terms and Conditions</a></li>

                    <li><a href="privacy-policy.html">Privacy Policy</a></li>
                    <li><a href="cookie-policy.html">Cookie Policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>


    </footer>
    </div>
  );
};

export default IntroContainer;
