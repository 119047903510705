import React from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import axios from 'axios';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState(); 
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const queryParams = new URLSearchParams(window.location.search);
    console.log('props');
    console.log(queryParams.get('payment'));
    console.log(atob(queryParams.get('payment')));   
    const urlParam  = atob(queryParams.get('payment')); 

    
    const paramJoinGroup = urlParam.split("||");

      
         var user_profile_url='';
         var spec_profile_url='';
     axios.get(`https://medalohaapi.cresol.in/customerAPI/GetCustomerDetailsByID?customer_id=` + paramJoinGroup[1].split('|')[1])
  .then(res => {
    if (res.data[0]['user_image'] != null) {
      user_profile_url = 'https://medalohaapi.cresol.in/public/uploads/profile/' + res.data[0]['user_image'];
      localStorage.setItem('user_image_url', user_profile_url);
    }
  })
  .catch(error => {
    console.error('Error fetching user details:', error);
  });

axios.get(`https://medalohaapi.cresol.in/specilistAPI/GetSpecialistPrivateDetailsByID?specialist_id=` + paramJoinGroup[1].split('|')[0])
  .then(res => {
    if (res.data[0]['profile_photo'] != null) {
      spec_profile_url = 'https://medalohaapi.cresol.in/public/uploads/docs/profileresize/' + res.data[0]['profile_photo'];
      console.log("spec_profile_url:", spec_profile_url);
      localStorage.setItem('spec_image_url', spec_profile_url);
    }
  })
  .catch(error => {
    console.error('Error fetching specialist details:', error);
  });


    //localStorage.setItem('Track','video');
    //localStorage.setItem('Track','audio');

    
    var VideoEnable = '';

    if(paramJoinGroup[2]=='Video'){
      console.log('video coming');
      localStorage.setItem('Track','video');
      var VideoEnable = true;
    } 
    else 
    {
      localStorage.setItem('Track','audio');
      var VideoEnable = false;
    }
 

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens  VideoEnable = {VideoEnable}  Chatname = {paramJoinGroup[0]}  ChatRoom = {paramJoinGroup[1]} />
      ) : (
        <Main>
          <ReconnectingNotification />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}
