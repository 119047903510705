import React, { SVGProps } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '0px',
  },
}));
export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  const classes = useStyles();
  return (



  

<img src="/logo.png" className={classes.twilioLogo} height="75%"/>
    
    
  );
}
